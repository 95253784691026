'use strict'

import {get} from 'bwindow';
import videoExtensions from 'video-extensions';

const videoExtNames = [...videoExtensions]

/**
 * @deprecated
 * @param src
 * @returns {*} */
function isVideoSrc(src) {
  console.warn(
    '[@the-/util-ui] isVideoSrc is now deprecated. Use @the-/util-string instead.',
  )
  const { pathname } = new URL(
    src || '',
    get('location.origin') || 'http://localhost',
  )
  const basename = pathname.split('/').pop()
  const extname = /\./.test(basename) && basename.split(/\./).pop()
  return videoExtNames.includes(extname)
}

export default isVideoSrc;
