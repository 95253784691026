'use strict'

import {get} from 'bwindow';

/**
 * Detect is serverside
 * @memberof module:@the-/util-ui
 * @function isServerSide
 * @returns {boolean}
 */
function isServerSide() {
  return !get('window.location')
}

export default isServerSide;
