'use strict'

import {v4 as uuid} from 'uuid';

/**
 * Generate new id
 * @memberof module:@the-/util-ui
 * @function newId
 * @param {Object} [options={}] - Optional settings
 * @param {string} [options.prefix-'the'] - Id prefix
 * @returns {string}
 */
function newId(options = {}) {
  const { prefix = 'the' } = options
  return [prefix, uuid()].join('-')
}

export default newId;
