'use strict'

export default Object.freeze(
  /**
   * Vendor Colors
   * @memberof module:@the-/const-ui
   * @namespace VendorColors
   */
  {
    FACEBOOK_COLOR: '#3b5998',
    TWITTER_COLOR: '#00acee',
  },
);
