'use strict'

const ThemeValues = Object.freeze(
  /**
   * Style theme values
   * @memberof module:@the-/const-ui
   * @namespace {string} ThemeValues
   */
  {
    activeOpacity: 0.8,
    altTextColor: '#AAA',
    backgroundColor: '#FFF',
    borderColor: '#AAA',
    captionBorderColor: '#F0F0F0',
    captionTextColor: '#999',
    containerWidth: 1024,
    contentPadding: 4,
    contentWidth: 480,
    dangerColor: '#B31818',
    disabledBackgroundColor: '#F0F0F0',
    disabledTextColor: '#CCC',
    dominantColor: '#ee8e0a',
    errorColor: '#B31818',
    fontFamily:
      'ヒラギノ角ゴ ProN W3, Hiragino Kaku Gothic ProN, メイリオ, Meiryo, sans-serif',
    fontSize: 16,
    headerHeight: 48,
    hoverOpacity: 0.9,
    infoColor: '#0C843A',
    inputBorderColor: '#AAA',
    inputShadowColor: 'rgba(0,0,0,.05)',
    largeMediaBreakpoint: 1200,
    lightBackgroundColor: '#F8F8F8',
    lightBorderColor: '#EEE',
    lightLinkColor: '#999',
    lightTextColor: '#AAA',
    mediumMediaBreakpoint: 992,
    overlayBackgroundColor: 'rgba(255,255,255,0.8)',
    overlayBorderColor: '#CCC',
    overlayTextColor: '#555',
    smallMediaBreakpoint: 768,
    tabInactiveColor: '#AAA',
    tappableHeight: 44,
    textColor: '#555',
    warnColor: '#9C9C19',
  },
)

export default ThemeValues;
